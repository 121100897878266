import React from 'react';

import styles from './Header.module.css';

import logo from 'assets/img/logo.png';

import { LanguagePicker } from 'interfaces/views/.components/LanguagePicker';

export const Header = () => {
  return (
    <header className={styles.header}>
      <img src={logo} className={styles.logo} alt="logo" />
      <LanguagePicker />
    </header>
  );
};
