import React, { useContext, useEffect, useRef, useState } from 'react';

import styles from './LanguagePicker.module.css';

import en from 'assets/img/flags/en.png';
import es from 'assets/img/flags/es.png';
import sk from 'assets/img/flags/sk.png';

import { Picker } from './_components/Picker';

import { LanguageContext } from 'interfaces/views/.tools/context/LanguageContext';
import { ResourcesContext } from 'interfaces/views/.tools/context/ResourcesContext';

export const LanguagePicker = () => {
  const languageContext = useContext(LanguageContext);
  const resources = useContext(ResourcesContext);

  const [isActive, setIsActive] = useState(false);

  const selectorsRef = useRef(null);

  const languageCodes = [en, es, sk];
  const languageImgs = ['en', 'es', 'sk'];
  const languageTranslations = ['ENGLISH', 'SPANISH', 'SLOVAK'];

  useEffect(() => {
    window.addEventListener('load', () => {
      setIsActive(true);
      selectorsRef.current.style.transform = 'rotate(0deg)';
      const delay = 0.2;
      let duration = delay * languageTranslations.length;
      selectorsRef.current.style.transition = `transform '${duration}'s ease-in-out`;
      setTimeout(() => {
        setIsActive(false);
        selectorsRef.current.style.transform = 'none';
        selectorsRef.current.style.transition = 'none';
      }, duration * 1000);
    });
  }, []);

  const onChangeLanguage = (type, newLang) => {
    let langTimeOut;
    clearTimeout(langTimeOut);
    langTimeOut = setTimeout(() => {
      languageContext.onChangeLang({ type: type, newLang: newLang });
      setIsActive(false);
    }, 100);
  };

  const onTogglePickers = () => {
    setIsActive(!isActive);
  };

  const len = languageTranslations.length;
  const degIncrement = 500 / len;
  const pickers = [];
  const radius = 75;
  const pickerAnimationDelay = 0.1;
  let i = 0;
  let delay;
  for (i = 0; i < len; i += 1) {
    const degrees = i * degIncrement;
    const rad = (degrees * Math.PI) / -720;
    delay = pickerAnimationDelay * i;
    let coordinateObject = {};
    let x = radius * Math.sin(rad);
    let y = radius * Math.cos(rad);
    coordinateObject.x = x;
    coordinateObject.y = y;

    pickers.push(
      <Picker
        delay={delay}
        isActive={isActive}
        lang={languageTranslations[i]}
        langCode={languageImgs[i]}
        langImg={languageCodes[i]}
        newCoordinates={coordinateObject}
        onChangeLanguage={onChangeLanguage}
      />
    );
  }

  return (
    <>
      <div className={styles.wrap}>
        <div
          className={`${styles.btn} ${isActive ? styles.active : ''}`}
          onClick={() => onTogglePickers()}
          title={resources.messages[languageContext.language]['language']}>
          <i className={resources.icons['language']} />
        </div>
      </div>
      <div className={styles.selectors}>
        <div ref={selectorsRef} className={styles.pickers}>
          {pickers}
        </div>
      </div>
    </>
  );
};
