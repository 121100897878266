export const functions = {
  handleClick: (event, url) => {
    event.preventDefault();
    window.open(url);
  },

  parseText: (rawText = '', param = {}) => {
    let text = rawText;
    Object.keys(param).forEach(key => {
      if (param[key]) {
        text = text.replace(`{:${key}}`, param[key]);
      } else {
        text = text.replace(`{:${key}}`, '');
      }
    });
    return text;
  }
};
