import React, { useContext } from 'react';

import styles from './Footer.module.css';

import { LanguageContext } from 'interfaces/views/.tools/context/LanguageContext';
import { ResourcesContext } from 'interfaces/views/.tools/context/ResourcesContext';

export const Footer = () => {
  const languageContext = useContext(LanguageContext);
  const resources = useContext(ResourcesContext);

  return (
    <footer className={styles.footer}>
      <span className={styles.text}>{resources.messages[languageContext.language]['copyright']}</span>
    </footer>
  );
};
