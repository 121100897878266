import React, { useContext } from 'react';

import styles from './LearnMore.module.css';

import eee from 'assets/img/eee.png';
import moodle from 'assets/img/moodle.png';
import online from 'assets/img/online.png';

import { LanguageContext } from 'interfaces/views/.tools/context/LanguageContext';
import { ResourcesContext } from 'interfaces/views/.tools/context/ResourcesContext';

import { functions } from 'interfaces/views/.tools/utils/functions';

export const LearnMore = () => {
  const languageContext = useContext(LanguageContext);
  const resources = useContext(ResourcesContext);

  return (
    <div className={styles.wrap}>
      <article className={styles.info}>
        <img
          className={styles.img}
          onClick={event => functions.handleClick(event, 'http://online.akademiaene.com')}
          src={online}
        />
        <h4
          className={`${styles.subtitle} ${styles.online}`}
          dangerouslySetInnerHTML={{
            __html: functions.parseText(resources.messages[languageContext.language]['online'])
          }}
        />
      </article>
      <article className={styles.info}>
        <img
          className={styles.img}
          onClick={event => functions.handleClick(event, 'http://www.estudiaespanolenespana.com')}
          src={eee}
        />
        <h4
          className={styles.subtitle}
          dangerouslySetInnerHTML={{
            __html: functions.parseText(resources.messages[languageContext.language]['eee'])
          }}
        />
      </article>
      <article className={styles.info}>
        <img
          className={styles.img}
          onClick={event => functions.handleClick(event, 'http://estudiaespanolenlinea.com')}
          src={moodle}
        />
        <h4
          className={styles.subtitle}
          dangerouslySetInnerHTML={{
            __html: functions.parseText(resources.messages[languageContext.language]['moodle'])
          }}
        />
      </article>
    </div>
  );
};
