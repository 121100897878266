import React from 'react';

import styles from './App.module.css';

import { Headquarters } from 'interfaces/views/.components/Headquarters';
import { Layout } from 'interfaces/views/.components/Layout';
import { LearnMore } from 'interfaces/views/.components/LearnMore';

import { LanguageProvider } from 'interfaces/views/.tools/provider/LanguageProvider';
import { ResourcesProvider } from 'interfaces/views/.tools/provider/ResourcesProvider';

export const App = () => {
  const layout = children => <Layout>{children}</Layout>;

  return (
    <ResourcesProvider>
      <LanguageProvider>
        {layout(
          <main className={styles.main}>
            <Headquarters />
            <LearnMore />
          </main>
        )}
      </LanguageProvider>
    </ResourcesProvider>
  );
};
