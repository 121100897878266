import React from 'react';

import { iconsResources } from 'config/icons';
import { langsResources } from 'config/lang';

import { ResourcesContext } from '../context/ResourcesContext';

export const ResourcesProvider = ({ children }) => {
  const resources = { ...iconsResources, ...langsResources };

  return <ResourcesContext.Provider value={resources}>{children}</ResourcesContext.Provider>;
};
