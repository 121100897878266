import React, { Fragment, useContext } from 'react';

import styles from './Headquarters.module.css';

import bratislava from 'assets/img/bratislava.jpg';
import liptovskyMikulas from 'assets/img/lm.jpg';
import trnava from 'assets/img/trnava.jpg';

import { LanguageContext } from 'interfaces/views/.tools/context/LanguageContext';
import { ResourcesContext } from 'interfaces/views/.tools/context/ResourcesContext';

import { functions } from 'interfaces/views/.tools/utils/functions';

export const Headquarters = () => {
  const languageContext = useContext(LanguageContext);
  const resources = useContext(ResourcesContext);

  return (
    <Fragment>
      <h1 className={styles.title}>{resources.messages[languageContext.language]['title']}</h1>
      <div className={styles.headquarters}>
        <article className={styles.headquarter}>
          <img
            alt="trnava"
            className={styles.img}
            title="Trnava"
            onClick={event => functions.handleClick(event, 'http://www.academiaenetrnava.com/')}
            src={trnava}
          />
          <h4 className={styles.subtitle}>{resources.messages[languageContext.language]['trnava']}</h4>
        </article>
        <article className={styles.headquarter}>
          <img
            alt="bratislava"
            className={styles.img}
            title="Bratislava"
            onClick={event => functions.handleClick(event, 'https://www.akademiaenebratislava.com/')}
            src={bratislava}
          />
          <h4 className={styles.subtitle}>{resources.messages[languageContext.language]['bratislava']}</h4>
        </article>
        <article className={styles.headquarter}>
          <img
            alt="liptovskyMikulas"
            className={styles.img}
            title="Liptovský Mikuláš"
            onClick={event => functions.handleClick(event, 'http://academiaeneliptmikulas.com/')}
            src={liptovskyMikulas}
          />
          <h4 className={styles.subtitle}>{resources.messages[languageContext.language]['liptovskyMikulas']}</h4>
        </article>
      </div>
    </Fragment>
  );
};
