import React from 'react';

import styles from './Picker.module.css';

export const Picker = ({ delay, isActive, lang, langCode, langImg, newCoordinates, onChangeLanguage }) => {
  const onUpdateLanguage = () => {
    onChangeLanguage(lang, langCode);
  };

  return (
    <img
      className={styles.picker}
      onClick={() => onUpdateLanguage()}
      src={langImg}
      style={
        isActive
          ? {
              transform: 'translate(' + newCoordinates.x + 'px, ' + newCoordinates.y + 'px)',
              transitionDelay: delay + 's'
            }
          : {
              transform: 'translate(0px,0px)',
              transitionDelay: delay + 's'
            }
      }
    />
  );
};
