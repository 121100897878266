import React, { useReducer } from 'react';

import { LanguageContext } from '../context/LanguageContext';

export const LanguageProvider = ({ children }) => {
  const initialState = { language: 'sk' };

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case 'ENGLISH':
        return {
          ...state,
          language: payload
        };

      case 'SPANISH':
        return {
          ...state,
          language: payload
        };

      case 'SLOVAK':
        return {
          ...state,
          language: payload
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LanguageContext.Provider
      value={{
        ...state,
        onChangeLang: ({ type, newLang }) => {
          dispatch({ type: type, payload: newLang });
        }
      }}>
      {children}
    </LanguageContext.Provider>
  );
};
